import { ChangeEvent, useContext, useEffect, useState } from "react"
import { Pagination } from "../../component/Pagination"
import close from '../../images/closeblack.svg'
import { Buyers, Excel } from "../../classes"
import { GlobalContext } from "../../context/GlobalContext"
import { Link } from "react-router-dom"
import { Loader } from "../../component/Loader"
import xlsx from "json-as-xlsx"
import { read, utils } from 'xlsx';


export const UserListPage = () => {
    const auth = useContext(GlobalContext)
    const [openPopup, setOpenPopup] = useState(false)
    const [load, setLoad] = useState(true)
    const [search, setSearch] = useState("")
    const [buyers, setBuyers] = useState(new Buyers())
    const [excel, setExcel] = useState<Excel[]>([{
        name: "",
        surname: "",
        minName: "",
        email: "",
        phone: "",
        password: ""
    }]);
    const [itemUpdate, setItemUpdate] = useState(false)
    const [itemCount, setItemCount] = useState(1)
    
    
    const changeHandler = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        excel[index] = { ...excel[index], [e.target.name]: e.target.value }
        setExcel(excel)     
        setItemUpdate(!itemUpdate)  
    }
    

  const downloadFile = () => {
    const excelList = new Buyers()
    setLoad(true)
    excelList.getData(auth.user.userToken, 1, "", true).then(function(responce: any){
        let content: [{}] = [{}]
        content.shift()
        //console.log(responce)
        responce.forEach((item: any)=>{
            content.push({
                id: item.pk,
                fio: `${item.surname} ${item.name} ${item.minName}`,
                phone: item.phone,
                email: item.email
            })
        })

        let data = [
            {
                sheet: "Adults",
                columns: [
                    { label: "ID", value: "id" },
                    { label: "ФИО", value: "fio" },
                    { label: "Номер телефона", value: "phone" },
                    { label: "Email", value: "email" },
                ],
                content: content!
            }, 
        ]
        let settings = {
        fileName: "Users",
        }
        xlsx(data, settings)
        setLoad(false)
    }).catch(function(error){
        setLoad(false)
    })
  }


    const handleFile = async (e: any) => {
        setLoad(true)
        const file = e.target.files[0]
        const fileArray = await file.arrayBuffer()
        const wb = read(fileArray)
        const ws = wb.Sheets[wb.SheetNames[0]]
        const data: Excel[] = utils.sheet_to_json<Excel>(ws)
        data.slice(0, 50)
        setExcel(data)                                                 
        setItemUpdate(!itemUpdate)
        setItemCount(data.length)
        setLoad(false)

    }

    const SetData = () => {
        buyers.setData(auth.user.userToken, excel).then(function(response){
            setOpenPopup(false)
            alert(response)
            GetData(1)
        }).catch(function(error){
            alert("error")
        })
    }

    const GetData = (page: number) => {
        setLoad(true)
        buyers.getData(auth.user.userToken, page, search).then(function(response){
            setLoad(false)
        }).catch(function(error){
            setLoad(false)
            alert("error")
        })
    }

    useEffect(()=>{
        GetData(1)
    },[])


    if(load){
        return(
            <Loader />
        )
    }

    return(
        <>
            <div className="content">
                <div className="user-add">
                    <div className="row">
                        <div className="col-md-6">
                            <form onSubmit={(event: React.FormEvent<HTMLFormElement>)=>{
                                event.preventDefault()
                                GetData(1)
                            }}>
                                <input type="text" value={search} onChange={(e)=>{
                                    setSearch(e.target.value)
                                }} placeholder="Поиск" />
                                <button>Найти</button>
                            </form>
                        </div>
                        <div className="col-md-6 right">

                            <button style={{backgroundColor: "#317c50"}} onClick={()=>{
                                downloadFile()
                            }}>Выгрузить в excel</button>
                            <button style={{marginLeft: 10}} onClick={()=>{setOpenPopup(true)}}>Добавить пользователя</button>
                        </div>
                    </div>
                </div>
                <div className='table-list'>
                    <div className='table-list__body'>
                        <table className="delivery">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>ФИО</th>
                                    <th>Номер телефона</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    buyers.list.length ? (
                                        buyers.list.map((item, index) => {
                                            return(
                                                <tr key={index}>
                                                    <td aria-label="ID">
                                                        <Link to={`/user/${item.pk}/`}>{item.pk}</Link>
                                                    </td>
                                                    <td aria-label="ФИО">{`${item.surname} ${item.name} ${item.minName}`}</td>
                                                    <td aria-label="Номер телефона">{item.phone}</td>
                                                    <td aria-label="Email">{item.email}</td>
                                                </tr> 
                                            )
                                        })
                                    ):""
                                } 
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="table-list__pager">
                    <Pagination 
                        page={buyers.page}
                        pageCount={buyers.pageCount}
                        setLoadReview={setLoad} 
                        SwitchPage={GetData}                         
                    />          
                </div>
            </div>

            {
                openPopup ? (
                    <div className="popup-form">
                        <div>
                            <div className="popup-close">
                                <img src={close} onClick={()=>{setOpenPopup(false)}} />
                            </div>
                            <div className="adduser-form"> 
                                {
                                    excel.length ? (
                                        excel.map((item, index) => {
                                            return(
                                                <div className="adduser-form__item">
                                                    <div className="row">
                                                        <div className="col-md-2 center">
                                                            <input type="text" placeholder="Email" name="email" value={item.email} onChange={(e)=>{changeHandler(e, index)}} />
                                                        </div>
                                                        <div className="col-md-2 center">
                                                            <input type="text" placeholder="Имя" name="name" value={item.name} onChange={(e)=>{changeHandler(e, index)}} />
                                                        </div>
                                                        <div className="col-md-2 center">
                                                            <input type="text" placeholder="Фамилия" name="surname" value={item.surname} onChange={(e)=>{changeHandler(e, index)}} />
                                                        </div>
                                                        <div className="col-md-2 center">
                                                            <input type="text" placeholder="Отчество" name="minName" value={item.minName} onChange={(e)=>{changeHandler(e, index)}} />
                                                        </div>
                                                        <div className="col-md-2 center">
                                                            <input type="text" placeholder="Телефон" name="phone" value={item.phone} onChange={(e)=>{changeHandler(e, index)}} />
                                                        </div>
                                                        <div className="col-md-2 center">
                                                            <input type="text" placeholder="Пароль" name="password" value={item.password} onChange={(e)=>{changeHandler(e, index)}} />
                                                        </div>
                                                    </div>
                                                    <div className="adduser-form__item-del">
                                                        {
                                                            excel.length-1 == index && excel.length-1 < 100 ? (
                                                                <span style={{marginRight: 20}} onClick={()=>{ 
                                                                    excel.push({
                                                                        name: "",
                                                                        surname: "",
                                                                        minName: "",
                                                                        email: "",
                                                                        phone: "",
                                                                        password: ""
                                                                    })
                                                                    setExcel(excel)                                                               
                                                                    setItemUpdate(!itemUpdate)
                                                                    setItemCount(itemCount+1)
                                                                }}>Добавить еще</span>
                                                            ):""
                                                        }
                                                        {
                                                            index != 0 ? (
                                                                <span onClick={()=>{
                                                                    excel.splice(index, 1)
                                                                    setExcel(excel)                                                               
                                                                    setItemUpdate(!itemUpdate)
                                                                    setItemCount(itemCount-1)
                                                                }}>Удалить</span>
                                                            ):""
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    ):""
                                }
                            </div>
                            <button onClick={()=>{SetData()}}>Сохранить</button>
                            <input type="file" onChange={handleFile} placeholder="excel" style={{marginLeft: 15}} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"  />
                            <span className="excel-des">({itemCount}/50 строк)</span>
                            <p style={{marginTop: 15, marginBottom: 0, fontSize: "13px"}}>
                                Пароль должен содержать не менее восьми (8) символов в том числе: по крайней мере один (1) буквы в верхнем регистре
                            </p>
                        </div>
                    </div>
                ):""
            }
        </>
    )
}