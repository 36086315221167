import { useContext, useState } from "react"
import { GlobalContext } from "../../context/GlobalContext"

export const SettingItem = (props: any) => {
    const auth = useContext(GlobalContext)
    const [form, setForm] = useState({...props.item})
    return(
        <label>
            {props.item.title}
            <input type="text" name="value" value={form.value} onChange={(e) => {
                setForm({ ...form, [e.target.name]: e.target.value })
                props.setting.list[props.index].value = e.target.value
            }} />
        </label>
    )
}