import { ChangeEvent, useContext, useState } from 'react';
import { GlobalContext } from '../../context/GlobalContext';

export const LoginPage = () => {
    const auth = useContext(GlobalContext)
    
    const [form, setForm] = useState({ 
        email: "",
        password: ""
    })
    
    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }
    
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        auth.user.Login(form.email, form.password).then(function(responce){
            if(responce.error == false){
                window.location.reload()
            }else{
                alert(responce.msg)
            }
        }).catch(function(error){
            alert("error")
        })
    }

    return (
        <form className='login-form' onSubmit={handleSubmit}>
            <h2>Авторизация</h2>
            <label>
                Email*
                <input type="email" required name="email" value={form.email} onChange={changeHandler} />
            </label>
            <label>
                Пароль*
                <input type="password" required name="password" value={form.password} onChange={changeHandler} />
            </label>
            <button>Войти</button>
            
        </form>
    )
}