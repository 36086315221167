import { useContext, useEffect, useState } from "react"
import { GlobalContext } from "../../context/GlobalContext"
import { Pagination } from "../../component/Pagination"
import { Mails, MailsItem, host } from "../../classes"
import close from '../../images/closeblack.svg'
import { Loader } from "../../component/Loader"
import { Link } from "react-router-dom"

export const MailsPage = () => {
    const auth = useContext(GlobalContext)
    const [openPopup, setOpenPopup] = useState(false)
    const [popupIndex, setPopupIndex] = useState(0)
    const [load, setLoad] = useState(true)
    const [search, setSearch] = useState("")
    const [mails, setMails] = useState(new Mails())
    const [image, setImage] = useState("")

    const GetData = (page: number) => {
        setLoad(true)
        mails.getData(auth.user.userToken, page, search).then(function(response){
            setLoad(false)
        }).catch(function(error){
            setLoad(false)
            alert("error")
        })
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        mails.list[popupIndex].setData(auth.user.userToken, image).then(function(response){
            setOpenPopup(false)
            alert(response)
            GetData(1)
        }).catch(function(error){
            alert("error")
        })
    }
    
    useEffect(()=>{
        GetData(1)
    },[])
    
    if(load){
        return(
            <Loader />
        )
    }

    return(
        <>
            <div className="content">
                <div className="user-add">
                    <div className="row">
                        <div className="col-md-6">
                            <form onSubmit={(event: React.FormEvent<HTMLFormElement>)=>{
                                event.preventDefault()
                                GetData(1)
                            }}>
                                <input type="text" value={search} onChange={(e)=>{
                                    setSearch(e.target.value)
                                }} placeholder="Поиск" />
                                <button>Найти</button>
                            </form>
                        </div>
                    </div>
                </div>

                <div className='table-list'>
                    <div className='table-list__body'>
                        <table className="delivery">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Название</th>
                                    <th>Описание</th>
                                    <th>Файл письма</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    mails.list.length ? (
                                        mails.list.map((item, index) => {
                                            return(
                                                <tr key={index}>
                                                    <td aria-label="ID">{item.pk}</td>
                                                    <td aria-label="Название">{item.title}</td>
                                                    <td aria-label="Описание">{item.desc}</td>
                                                    <td aria-label="Файл письма">
                                                        <Link to={`${host}${item.field}`} target="_blank">
                                                            Ссылка на файл
                                                        </Link>
                                                    </td>
                                                    <td aria-label="Кол-во участников">
                                                        <button onClick={()=>{
                                                            setPopupIndex(index)
                                                            setOpenPopup(true)
                                                        }}>Настроить</button>
                                                    </td>
                                                </tr> 
                                            )
                                        })
                                    ):""
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="table-list__pager">    
                    <Pagination 
                        page={mails.page}
                        pageCount={mails.pageCount}
                        setLoadReview={setLoad} 
                        SwitchPage={GetData}                         
                    />      
                </div>
            </div>


            {
                openPopup ? (
                    <div className="popup-form mini">
                        <div>
                            <div className="popup-close">
                                <img src={close} onClick={()=>{setOpenPopup(false)}} />
                            </div>
                            <div className="adduser-form"> 
                                <form onSubmit={handleSubmit}>
                                    <label>
                                        <input type="file" required accept=".html" onChange={(e: any)=>{
                                            setImage(e.target.files[0])
                                        }} />
                                    </label>
                                    <button>Обновить</button>
                                </form>      
                            </div>
                        </div>
                    </div>
                ):""
            }
        </>
    )
}