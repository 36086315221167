import { ChangeEvent, useContext, useEffect, useState } from "react"
import { GlobalContext } from "../../context/GlobalContext"
import { Setting, Settings } from "../../classes"
import { Loader } from "../../component/Loader"
import { SettingItem } from "../../component/SettingItem"

export const SettingPage = () => {
    const auth = useContext(GlobalContext)
    const [load, setLoad] = useState(true)
    const [setting, setSetting] = useState(new Settings())
    
    const [sArray, setSArray] = useState<Setting[]>([])

    const GetData = (page: number) => {
        setLoad(true)
        setting.getData(auth.user.userToken).then(function(response){
            console.log(response)
            setSArray(setting.list)
            setLoad(false)
        }).catch(function(error){
            setLoad(false)
            alert("error")
        })
    }

    const SetData = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setting.setData(auth.user.userToken).then(function(response){
            alert(response)
            GetData(1)
        }).catch(function(error){
            alert("error")
        })

    }

    useEffect(()=>{
        GetData(1)
    },[])
    
    if(load){
        return(
            <Loader />
        )
    }

    return (
        <>
            <div className="setting-body">
                <form onSubmit={SetData}>
                    <div className="row">
                        {
                            sArray.length ? (
                                sArray.map((item, index) => {
                                    return(
                                        <div className="col-md-4">
                                            <SettingItem item={item} index={index} setting={setting} />
                                        </div> 
                                    )
                                })
                            ):""
                        }
                    </div>
                    <button>Сохранить</button>
                </form>
            </div>
        </>
    )
}