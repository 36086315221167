import React, { useContext } from 'react'; 
import './App.css';
import './bootstrap.css';
import { Route, Routes } from 'react-router-dom';
import { useMyRoutes } from './routes';
import { GlobalContext } from './context/GlobalContext'
import { Layout } from "./layouts/layout" 
import { User } from './classes';
import { Sidebar } from './component/Sidebar';

function App() {
    const auth = useContext(GlobalContext)
    const routes = useMyRoutes() 
    return (
        <GlobalContext.Provider value={{
            user: new User,
        }}> 

            <div className='body-wrapper'>
                {
                    auth.user.userToken != "" ? (
                        <Sidebar />
                    ):""
                }
                <div className={auth.user.userToken != "" ? "sidebar-body":""}>
                    <Routes>
                        <Route element={<Layout />}>{routes}</Route>
                    </Routes> 
                </div>
            </div>
        </GlobalContext.Provider>
    );
}

export default App;
