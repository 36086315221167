import { ChangeEvent } from "react"

export const Suitable = (props: any) => {


    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const array = props.suitable.map((item: any, index: number) => {
            return {
                "pk": item.pk,
                "image": item.image, 
                "title": item.title, 
                "miniDesc": item.miniDesc
            }
        })
        array[props.index] = {...array[props.index], [event.target.name]: event.target.value}
        props.setSuitable(array)
    }
    
    return(
        <div className="suitable-form">
            <div className="row">
                <div className="col-md-6">
                    <label>
                        <input placeholder="Ссылка на фото" required name="image" value={props.item.image} onChange={changeHandler} />
                    </label>
                </div>
                <div className="col-md-6">
                    <label>
                        <input placeholder="Название" required name="title" value={props.item.title} onChange={changeHandler} />
                    </label>
                </div>
                <div className="col-md-12">
                    <label>
                        <input placeholder="Описание" required name="miniDesc" value={props.item.miniDesc} onChange={changeHandler} />
                    </label>
                </div>
            </div>
            <p>
                {
                    props.index == props.suitable.length - 1 ? (
                        <span 
                            onClick={()=>{
                                const array = props.suitable.map((item: any, index: number) => {
                                    return {
                                        "pk": item.pk,
                                        "image": item.image, 
                                        "title": item.title, 
                                        "miniDesc": item.miniDesc
                                    }
                                })
                                array.push({
                                    "pk": 0,
                                    "image": "", 
                                    "title": "", 
                                    "miniDesc": ""
                                })
                                props.setSuitable(array)
                            }}
                        >
                            Добавить
                        </span>
                    ):""
                }
                {
                    props.index > 0 ? (
                        <span 
                            onClick={()=>{
                                const array = props.suitable.map((item: any, index: number) => {
                                    return {
                                        "pk": item.pk,
                                        "image": item.image, 
                                        "title": item.title, 
                                        "miniDesc": item.miniDesc
                                    }
                                })
                                array.splice(props.index, 1)
                                props.setSuitable(array)
                            }} 
                            style={{
                                marginLeft: 10
                            }}
                        >
                            Удалить
                        </span>
                    ):""
                }   
            </p> 
        </div>
    )
}