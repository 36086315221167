import { useContext, useEffect, useState } from "react"
import { GlobalContext } from "../../context/GlobalContext"
import { Loader } from "../../component/Loader"
import close from '../../images/closeblack.svg'
import copy from '../../images/copy.svg'

import { Pagination } from "../../component/Pagination"
import { MediaManager, host } from "../../classes"

export const MediaPage = () => {
    const auth = useContext(GlobalContext)
    const [openPopup, setOpenPopup] = useState(false)
    const [load, setLoad] = useState(true)
    const [media, setMedia] = useState(new MediaManager())

    const [image, setImage] = useState("")

    const DelItem = (index: number) => {
        setLoad(true)
        media.list[index].delData(auth.user.userToken).then(function(response){
            GetData(1)
        }).catch(function(error){
            alert("error")
        })

    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        media.setData(auth.user.userToken, image).then(function(response){
            setOpenPopup(false)
            alert(response)
            GetData(1)
        }).catch(function(error){
            alert("error")
        })
    }

    const GetData = (page: number) => {
        setLoad(true)
        media.getData(auth.user.userToken, page).then(function(response){
            setLoad(false)
        }).catch(function(error){
            setLoad(false)
            alert("error")
        })
    }

    useEffect(()=>{
        GetData(1)
    },[])

    if(load){
        return(
            <Loader />
        )
    }
    
    return(
        <>
            <div className="content">
                <div className="user-add">
                    <div className="row">
                        <div className="col-md-6">
                        </div>
                        <div className="col-md-6 right">
                            <button onClick={()=>{
                                setOpenPopup(true)
                            }}>Добавить фото</button>
                        </div>
                    </div>
                </div>
                <div className='table-list'>
                    <div className='table-list__body'>
                        <div className="row">
                            {
                                media.list.length ? (
                                    media.list.map((item, index) => {
                                        return(
                                            <div key={index} className="col-lg-2">
                                                <div className="media-item" style={{backgroundImage: `url("${host}${item.image}")`}}>
                                                    <div 
                                                        style={{
                                                            top: 40
                                                        }}
                                                        onClick={()=>{
                                                            navigator.clipboard.writeText(`${host}${item.image}`)
                                                        }}
                                                    >
                                                        <img 
                                                            src={copy} 
                                                            style={{
                                                                width: 25,
                                                                marginTop: 7,
                                                                marginLeft: 7
                                                            }} 
                                                        />
                                                    </div>
                                                    <div onClick={()=>{DelItem(index)}}>
                                                        <img src={close} />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                ):""
                            }
                        </div>
                    </div>
                </div>
                <div className="table-list__pager">
                    <Pagination 
                        page={media.page}
                        pageCount={media.pageCount}
                        setLoadReview={setLoad} 
                        SwitchPage={GetData}                         
                    />          
                </div>
            </div>


            {
                openPopup ? (
                    <div className="popup-form mini">
                        <div>
                            <div className="popup-close">
                                <img src={close} onClick={()=>{setOpenPopup(false)}} />
                            </div>
                            <div className="adduser-form"> 
                                <form onSubmit={handleSubmit}>
                                    <label>
                                        <input type="file" required accept=".jpg,.jpeg,.png" onChange={(e: any)=>{
                                            setImage(e.target.files[0])
                                        }} />
                                    </label>
                                    <button>Добавить</button>
                                </form>      
                            </div>
                        </div>
                    </div>
                ):""
            }
        </>
    )
}