
import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom'
import menu from '../../images/menu.svg'
import close from '../../images/close.svg'
import { GlobalContext } from '../../context/GlobalContext';

export const Sidebar = () => {
    const auth = useContext(GlobalContext)
    const [openSidebar, setOpenSidebar] = useState(false)
 

    return(
        <>
            <img className='open-menu' src={menu} onClick={()=>{setOpenSidebar(true)}} />

            <div className={`sidebar-wrapper ${openSidebar ? "active":""}`}>
                <div className='right'>
                    <img src={close} onClick={()=>{setOpenSidebar(false)}} />
                </div>
                <ul>
                    <li>
                        <Link to={"/"}>Участники</Link>
                    </li>
                    <li>
                        <Link to={"/group/"}>Группы участников</Link>
                    </li>
                    <li>
                        <Link to={"/mails/"}>Редактирование писем</Link>
                    </li>
                    <li>
                        <Link to={"/courses/"}>Курсы</Link>
                    </li>
                    <li>
                        <Link to={"/media/"}>Медиафайлы</Link>
                    </li>
                    <li>
                        <Link to={"/teachers/"}>Преподаватели</Link>
                    </li>
                    <li>
                        <Link to={"/orders/"}>Заказы</Link>
                    </li>
                    <li>
                        <Link to={"/reviews/"}>Отзывы</Link>
                    </li>
                    <li>
                        <Link to={"/setting/"}>Настройки</Link>
                    </li>
                    <li>
                        <Link to={"/"} onClick={()=>{
                            auth.user.Logout()
                            window.location.replace('/')
                        }}>Выход</Link>
                    </li>
                </ul>
            </div>
        </>
    )
}   