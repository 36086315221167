import { useContext, useEffect, useState } from "react"
import { GlobalContext } from "../../context/GlobalContext"
import { Loader } from "../../component/Loader"
import { Pagination } from "../../component/Pagination"
import { Link } from "react-router-dom"
import { Orders } from "../../classes"
import close from '../../images/closeblack.svg'

export const OrdersPage = () => {
    const auth = useContext(GlobalContext)
    const [openPopup, setOpenPopup] = useState(false)
    const [popupIndex, setPopupIndex] = useState(0)
    const [load, setLoad] = useState(true)
    const [search, setSearch] = useState("")
    const [orders, setOrders] = useState(new Orders())

    const GetData = (page: number) => {
        setLoad(true)
        orders.getData(auth.user.userToken, page, search).then(function(response){
            setLoad(false)
        }).catch(function(error){
            setLoad(false)
            alert("error")
        })
    }

    const GetOrder = (itemId: number) => {
        setLoad(true)
        orders.list[itemId].getData(auth.user.userToken).then(function(response){
            setOpenPopup(true)
            setPopupIndex(itemId)
            setLoad(false)
        }).catch(function(error){
            setLoad(false)
            alert("error")
        })
    }

    useEffect(()=>{
        GetData(1)
    },[])

    if(load){
        return(
            <Loader />
        )
    }

    return(
        <>
            <div className="content">
                <div className="user-add">
                    <div className="row">
                        <div className="col-md-6">
                            <form onSubmit={(event: React.FormEvent<HTMLFormElement>)=>{
                                event.preventDefault()
                                GetData(1)
                            }}>
                                <input type="text" value={search} onChange={(e)=>{
                                    setSearch(e.target.value)
                                }} placeholder="Поиск" />
                                <button>Найти</button>
                            </form>
                        </div>
                        <div className="col-md-6 right">
                        </div>
                    </div>
                </div>
                <div className='table-list'>
                    <div className='table-list__body'>
                        <table className="delivery">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Статус</th>
                                    <th>Пользователь</th>
                                    <th>Дата</th>
                                    <th>Сумма</th>
                                    <th>Состав заказа</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    orders.list.length ? (
                                        orders.list.map((item, index) => {
                                            return(
                                                <tr key={index}>
                                                    <td aria-label="ID">{item.pk}</td>
                                                    <td aria-label="Статус">{item.status}</td>
                                                    <td aria-label="Пользователь">
                                                        <Link to={`/user/${item.user.pk}/`}>{item.user.email}</Link>
                                                    </td>
                                                    <td aria-label="Дата">
                                                        {item.createDate.split("T")[1].split(".")[0]} /
                                                        {item.createDate.split("T")[0]} 
                                                    </td>
                                                    <td aria-label="Сумма">
                                                        {
                                                            item.cost != item.finalCost ? (
                                                                <>
                                                                    <span className="desc-cost">{item.cost} руб</span><br/>
                                                                </>
                                                            ):""
                                                        }
                                                        {item.finalCost} руб
                                                    </td>
                                                    <td aria-label="Состав заказа">
                                                        <button onClick={()=>{GetOrder(index)}}>Товары</button>
                                                    </td> 
                                                </tr> 
                                            )
                                        })
                                    ):""
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="table-list__pager">
                    <Pagination 
                        page={orders.page}
                        pageCount={orders.pageCount}
                        setLoadReview={setLoad} 
                        SwitchPage={GetData}                         
                    />          
                </div>
            </div>

            {
                openPopup ? (
                    <div className="popup-form">
                        <div>
                            <div className="popup-close">
                                <img src={close} onClick={()=>{setOpenPopup(false)}} />
                            </div>
                            <div className="adduser-form"> 
                                {
                                    orders.list[popupIndex].order_item.length ? (
                                        orders.list[popupIndex].order_item.map((item, index) => {
                                            return(
                                                <div key={index} className="popup-order__item row">
                                                    <div className="col-6">
                                                        <div className="grid">
                                                            <Link to={`/module/${item.module.pk}/`}>
                                                                {item.module.title}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 right">
                                                        {
                                                            item.cost != item.finalCost ? (
                                                                <>
                                                                    <span className="desc-cost">{item.cost} руб</span><br/>
                                                                </>
                                                            ):""
                                                        }
                                                        {item.finalCost} руб
                                                    </div>
                                                </div>
                                            )
                                        })
                                    ):""
                                }
                            </div>
                        </div>
                    </div>
                ):""
            }
        </>
    )
}