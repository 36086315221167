import { Link } from "react-router-dom"

export const Pagination = (props: any) => {
 
    const UpdatePagination = () => {
        const start = props.page - 3 > 0 ? props.page - 3 : 1
        const end = props.page + 3 < props.pageCount ? props.page + 3 : props.pageCount
        let block = []
        
        if(props.page > 1)
            block.push(
                <>
                    <div className="pagination__item"> 
                        <span onClick={()=>{props.SwitchPage(props.page - 1)}}>Назад</span>
                    </div>
                    {
                        start > 1 ? (
                            <>
                                <div className={`pagination__item`}>
                                    <span onClick={()=>{props.SwitchPage(1)}}>1</span>
                                </div>
                                {
                                    start > 2 ? (
                                        <div className={`pagination__item`}>
                                            <div>
                                                ...
                                            </div>
                                        </div>
                                    ):""
                                }
                            </>
                        ):""
                    }
                </>
            )

        for(let i=start; i<=end; i++){
            block.push(
                <div className={`pagination__item ${i == props.page ? "active":""}`}>
                    <span onClick={()=>{props.SwitchPage(i)}}>{i}</span>
                </div>
            )
        }

        if(props.page < props.pageCount)
            block.push(
                <>
                    {
                        end < props.pageCount ? (
                            <>
                                {
                                    end < props.pageCount - 1 ? (
                                        <div className={`pagination__item`}>
                                            <div>
                                                ...
                                            </div>
                                        </div>
                                    ):""
                                }
                                <div className={`pagination__item`}>
                                    <span onClick={()=>{props.SwitchPage(props.pageCount)}}>
                                        {props.pageCount}
                                    </span>
                                </div>
                                
                            </>
                        ):""
                    }
                    <div className="pagination__item"> 
                        <span onClick={()=>{props.SwitchPage(props.page + 1)}}>Вперед</span>
                    </div>
                </>
            )
        return block
    }
    
    return (
        <>
            {
                props.pageCount > 1 ? (
                    <div className="pagination">
                        {UpdatePagination()}
                    </div>
                ):""
            }
        </>
    )
}