import { ChangeEvent, useContext, useEffect, useState } from "react"
import { GlobalContext } from "../../context/GlobalContext"
import { Teachers } from "../../classes"
import { Loader } from "../../component/Loader"
import close from '../../images/closeblack.svg'
import { Link } from "react-router-dom"
import { Pagination } from "../../component/Pagination"

export const TeachersPage = () => {
    const auth = useContext(GlobalContext)
    const [openPopup, setOpenPopup] = useState(false)
    const [openPopupUpdate, setOpenPopupUpdate] = useState(false)
    const [updateIndex, setUpdateIndex] = useState(0)
    const [load, setLoad] = useState(true)
    const [search, setSearch] = useState("")
    const [teachers, setTeachers] = useState(new Teachers)

    const [formUpdate, setFormUpdate] = useState({
        title: "",
        desc: "",
        image: ""
    })

    const [form, setForm] = useState({
        title: "",
        desc: "",
        image: ""
    })

    const changeHandlerUpdate = (event: ChangeEvent<HTMLInputElement>) => {
        setFormUpdate({ ...formUpdate, [event.target.name]: event.target.value })
    }
    const SetDataUpdate = () => {
        setLoad(true) 
        teachers.list[updateIndex].updateData(auth.user.userToken, formUpdate).then(function(response){
            alert(response)
            setOpenPopupUpdate(false)
            GetData(1)
        }).catch(function(error){
            alert("error")
        })
    }

    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }
    const SetData = () => {
        setLoad(true) 
        teachers.setData(auth.user.userToken, form).then(function(response){
            setOpenPopup(false)
            alert(response)
            setLoad(false)
            GetData(1)
        }).catch(function(error){
            setLoad(false)
            alert("error")
        })
    }
    

    
    const GetData = (page: number) => {
        setLoad(true) 
        teachers.getData(auth.user.userToken, page, search).then(function(response){
            setLoad(false)
        }).catch(function(error){
            setLoad(false)
            alert("error")
        })
    }

    useEffect(()=>{
        GetData(1)
    },[])
    
    if(load){
        return(
            <Loader />
        )
    }

    return(
        <>
            <div className="content">
                <div className="user-add">
                    <div className="row">
                        <div className="col-md-6">
                            <form onSubmit={(event: React.FormEvent<HTMLFormElement>)=>{
                                event.preventDefault()
                                GetData(1)
                            }}>
                                <input type="text" value={search} onChange={(e)=>{
                                    setSearch(e.target.value)
                                }} placeholder="Поиск" />
                                <button>Найти</button>
                            </form>
                        </div>
                        <div className="col-md-6 right">
                            <button onClick={()=>{setOpenPopup(true)}}>Добавить преподавателя</button>
                        </div>
                    </div>
                </div>
                <div className='table-list'>
                    <div className='table-list__body'>
                        <table className="delivery">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>ФИО</th>
                                    <th>Должность</th>
                                    <th style={{width: "200px", maxWidth: "100%"}}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    teachers.list.length ? (
                                        teachers.list.map((item, index) => {
                                            return(
                                                <tr key={index}>
                                                    <td aria-label="ID">
                                                        {item.pk}
                                                    </td>
                                                    <td aria-label="Название курса">{item.title}</td>
                                                    <td aria-label="Должность">{item.desc}</td>
                                                    <td aria-label="Активность">
                                                        <button 
                                                            onClick={()=>{
                                                                setFormUpdate({
                                                                    title: item.title,
                                                                    desc: item.desc,
                                                                    image: ""
                                                                })
                                                                setUpdateIndex(index)
                                                                setOpenPopupUpdate(true)
                                                            }}
                                                            style={{marginBottom: 3}}
                                                        >Изменить</button>
                                                        <button 
                                                            onClick={()=>{
                                                                item.delData(auth.user.userToken).then(function(response){
                                                                    alert(response)
                                                                    GetData(1)
                                                                }).catch(function(error){
                                                                    alert("error")
                                                                })
                                                            }}
                                                            style={{backgroundColor: "red", marginLeft: 5, marginBottom: 3}}
                                                        >Удалить</button>
                                                    </td>
                                                </tr> 
                                            )
                                        })
                                    ):""
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="table-list__pager">    
                    <Pagination 
                        page={teachers.page}
                        pageCount={teachers.pageCount}
                        setLoadReview={setLoad} 
                        SwitchPage={GetData}                         
                    />      
                </div>
            </div>

            {
                openPopupUpdate ? (
                    <div className="popup-form">
                        <div>
                            <div className="popup-close">
                                <img src={close} onClick={()=>{setOpenPopupUpdate(false)}} />
                            </div>
                            <div className="adduser-form"> 
                                <form onSubmit={SetDataUpdate}>
                                    <label>
                                        ФИО
                                        <input maxLength={255} name="title" required value={formUpdate.title} onChange={changeHandlerUpdate} />
                                    </label>
                                    <label>
                                        Должность
                                        <input maxLength={255} name="desc" required value={formUpdate.desc} onChange={changeHandlerUpdate} />
                                    </label>
                                    <label>
                                        Фото
                                        <input type="file" accept=".jpg,.jpeg,.png" onChange={(e: any)=>{
                                            setFormUpdate({ ...formUpdate, "image": e.target.files[0]})
                                        }} />
                                    </label>
                                    <button>Создать</button>
                                </form>
                            </div>
                        </div>
                    </div>
                ):""
            }

            {
                openPopup ? (
                    <div className="popup-form">
                        <div>
                            <div className="popup-close">
                                <img src={close} onClick={()=>{setOpenPopup(false)}} />
                            </div>
                            <div className="adduser-form"> 
                                <form onSubmit={SetData}>
                                    <label>
                                        ФИО
                                        <input maxLength={255} name="title" required value={form.title} onChange={changeHandler} />
                                    </label>
                                    <label>
                                        Должность
                                        <input maxLength={255} name="desc" required value={form.desc} onChange={changeHandler} />
                                    </label>
                                    <label>
                                        Фото
                                        <input type="file" required accept=".jpg,.jpeg,.png" onChange={(e: any)=>{
                                            setForm({ ...form, "image": e.target.files[0]})
                                        }} />
                                    </label>
                                    <button>Создать</button>
                                </form>
                            </div>
                        </div>
                    </div>
                ):""
            }
        </>
    )
}