import { ChangeEvent, useContext, useEffect, useState } from "react"
import { GlobalContext } from "../../context/GlobalContext"
import { Loader } from "../../component/Loader"
import close from '../../images/closeblack.svg'
import { Pagination } from "../../component/Pagination"
import { Reviews, host } from "../../classes"
import axios from "axios"

export const ReviewsPage = () => {
    const auth = useContext(GlobalContext)
    const [openPopup, setOpenPopup] = useState(false)

    const [openUpdatePopup, setOpenUpdatePopup] = useState(false)
    const [popupIndex, setPopupIndex] = useState(0)
    const [load, setLoad] = useState(!true)
    const [search, setSearch] = useState("")
    const [reviews, setReviews] = useState(new Reviews)
    const [courses, setCourses] = useState([{
        pk: 0,
        title: 0
    }])

    const [formUpdate, setFormUpdate] = useState({
        course: 0,
        title: "",
        miniDesc: ""
    })

    const [form, setForm] = useState({
        course: 0,
        title: "",
        miniDesc: ""
    })

    const GetCourses = async () => {
        await axios.get(`${host}courses-list/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${auth.user.userToken}`
            }
        }).then((res) => { 
            setForm({
                course: res.data[0].pk,
                title: "",
                miniDesc: ""
            })
            setCourses(res.data)
        }).catch(function(error){
            console.log(error)
        })
    }

    const GetData = (page: number) => {
        setLoad(true)
        GetCourses().then(function(responce){}).catch(function(error){alert("error")})
        reviews.getData(auth.user.userToken, page, search).then(function(response){
            setLoad(false)
        }).catch(function(error){
            alert("error")
        })
    }

    useEffect(()=>{
        GetData(1)
    },[])

    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({...form, [event.target.name]: event.target.value})
    }
    
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        setLoad(true)
        reviews.setData(auth.user.userToken, form).then(function(responce){
            alert(responce)
            setOpenPopup(false)
            GetData(1)
        }).catch(function(error){
            alert("error")
        })
    }


    const changeUpdateHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setFormUpdate({...formUpdate, [event.target.name]: event.target.value})
    }

    const handleUpdateSubmit = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        setLoad(true)
        console.log(formUpdate)
        reviews.list[popupIndex].updateData(auth.user.userToken, formUpdate).then(function(responce){
            alert(responce)
            setOpenUpdatePopup(false)
            GetData(1)
        }).catch(function(error){
            alert("error")
        })
    }


    if(load){
        return(
            <Loader />
        )
    }

    return(
        <>
            <div className="content">
                <div className="user-add">
                    <div className="row">
                        <div className="col-md-6">
                            <form onSubmit={(event: React.FormEvent<HTMLFormElement>)=>{
                                event.preventDefault()
                                GetData(1)
                            }}>
                                <input type="text" value={search} onChange={(e)=>{
                                    setSearch(e.target.value)
                                }} placeholder="Поиск" />
                                <button>Найти</button>
                            </form>
                        </div>
                        <div className="col-md-6 right">
                            <button 
                                onClick={()=>{
                                    setOpenPopup(true)
                                }}
                            >Добавить отзыв</button>
                        </div>
                    </div>
                </div>
                <div className='table-list'>
                    <div className='table-list__body'>
                        <table className="delivery">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Курс</th>
                                    <th>ФИО</th>
                                    <th>Дата</th>
                                    <th style={{width: 330}}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    reviews.list.length ? (
                                        reviews.list.map((item, index) => {
                                            return(
                                                <tr key={index}>
                                                    <td aria-label="ID">{item.pk}</td>
                                                    <td aria-label="Курс">{item.course.pk}</td>
                                                    <td aria-label="ФИО">{item.fio}</td>
                                                    <td aria-label="Дата">{item.createDate.split("T")[0]}</td>
                                                    <td aria-label="">
                                                        <button 
                                                            style={{
                                                                marginRight: 10
                                                            }}
                                                            onClick={()=>{
                                                                setFormUpdate({
                                                                    course: Number(item.course.pk),
                                                                    title: item.fio,
                                                                    miniDesc: item.review
                                                                })
                                                                setPopupIndex(index)
                                                                setOpenUpdatePopup(true)
                                                            }}
                                                        >
                                                            Изменить
                                                        </button>
                                                        <button
                                                            style={{
                                                                backgroundColor: 'red'
                                                            }}
                                                            onClick={()=>{
                                                                setLoad(true)
                                                                item.delData(auth.user.userToken).then(function(responce){
                                                                    alert(responce) 
                                                                    GetData(1)
                                                                }).catch(function(error){
                                                                    alert("error")
                                                                })
                                                            }}
                                                        >
                                                            Удалить
                                                        </button>
                                                    </td>
                                                </tr> 
                                            )
                                        })
                                    ):""
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="table-list__pager">
                    <Pagination 
                        page={reviews.page}
                        pageCount={reviews.pageCount}
                        setLoadReview={setLoad} 
                        SwitchPage={GetData}                         
                    />          
                </div>
            </div>

            {
                openPopup ? (
                    <div className="popup-form">
                        <div>
                            <div className="popup-close">
                                <img src={close} onClick={()=>{setOpenPopup(false)}} />
                            </div>
                            <div className="adduser-form"> 
                                <form onSubmit={handleSubmit}>
                                    <label>
                                        ФИО
                                        <input maxLength={255} required type="text" name="title" value={form.title} onChange={changeHandler} />
                                    </label>
                                    <label>
                                        Отзыв
                                        <textarea maxLength={510} required name="miniDesc" value={form.miniDesc} onChange={(e)=>{
                                            setForm({
                                                ...form,
                                                miniDesc: e.target.value
                                            })
                                        }} />
                                    </label>
                                    <label>
                                        Курс
                                        <select
                                            required
                                            value={form.course} 
                                            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                                                setForm({
                                                    ...form,
                                                    course: Number(event.target.value)
                                                })
                                            }}
                                        >
                                            {
                                                courses.length ? (
                                                    courses.map((item, index) => {
                                                        return(
                                                            <option value={item.pk}>{item.title}</option>
                                                        )
                                                    })
                                                ):""
                                            }
                                        </select>
                                    </label>

                                    <button>Создать</button>
                                </form>
                            </div>
                        </div>
                    </div>
                ):""
            }

            {
                openUpdatePopup ? (
                    <div className="popup-form">
                        <div>
                            <div className="popup-close">
                                <img src={close} onClick={()=>{setOpenUpdatePopup(false)}} />
                            </div>
                            <div className="adduser-form"> 
                                <form onSubmit={handleUpdateSubmit}>
                                    <label>
                                        ФИО
                                        <input maxLength={255} required type="text" name="title" value={formUpdate.title} onChange={changeUpdateHandler} />
                                    </label>
                                    <label>
                                        Отзыв
                                        <textarea maxLength={510} required name="miniDesc" value={formUpdate.miniDesc} onChange={(e)=>{
                                            setFormUpdate({
                                                ...formUpdate,
                                                miniDesc: e.target.value
                                            })
                                        }} />
                                    </label>
                                    <label>
                                        Курс
                                        <select
                                            required
                                            value={formUpdate.course} 
                                            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                                                setFormUpdate({
                                                    ...formUpdate,
                                                    course: Number(event.target.value)
                                                })
                                            }}
                                        >
                                            {
                                                courses.length ? (
                                                    courses.map((item, index) => {
                                                        return(
                                                            <option value={item.pk}>{item.title}</option>
                                                        )
                                                    })
                                                ):""
                                            }
                                        </select>
                                    </label>

                                    <button>Создать</button>
                                </form>
                            </div>
                        </div>
                    </div>
                ):""
            }
        </>
    )
}